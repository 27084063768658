import React, { useState } from "react";
import { useEffect } from "react";
import Select from 'react-select';

function CustomSelect({ options, sendValue, texts})  {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    sendValue(selectedOption);
  }, [selectedOption]);

  return (
    <>
      <Select 
        placeholder={texts.contact_form_product}
        unstyled
        className='input'
        classNamePrefix='input-select'
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
        classNames={{
          placeholder: (state) => state.isFocused ? 'label-focus' : 'label-blur'
        }}
      />
    </>
  );
}

export default CustomSelect;