import React from 'react';
import Layout from '../components/layout';
import Svg from '../components/svg';
import ContactForm from '../components/contact-form';
import mobileImage from '../images/place-mob.jpg';
import desktopImage from '../images/place.jpg';
import SEO from '../components/seo';
import A80 from '../animations/a80';
import A160 from '../animations/a160';
import A400 from '../animations/a400';

export default ({ pageContext: { content, pages, lang, texts, langs, } }) => {
  const data = content.data;
  let child;
  const items = pages.filter((page) => page[lang].template === 'contacts')[0][
    lang
  ].items;
  const openForm = () => {
    child.toggleForm(true);
  };

  return (
    <Layout pages={pages} lang={lang} texts={texts} langs={langs} hideFooter={true}>
      <SEO
        description={data.seo_description}
        title={data.seo_title}
        image={data.seo_image}
      />
      <div className='layer-top layer-top-contact'>
        <A160 className='contacts-liquid-1' />
        <A400 className='contacts-liquid-2' />
        <A80 className='contacts-liquid-3' />
        <div className='relative'>
          <div className='wrap-full pl-xl pr-xxl'>
            <div className='group group-contact'>
              <div className='left'>
                <h1
                  data-sal='slide-right'
                  data-sal-duration='1000'
                  className='con'
                  dangerouslySetInnerHTML={{ __html: data.intro_title }}
                />
                <div
                  data-sal='slide-up'
                  className='con'
                  data-sal-duration='1000'
                >
                  <div className='contacts__list' dangerouslySetInnerHTML={{ __html: data.intro_text }} />
                </div>
              </div>
              <div className='right'
                data-sal='slide-left'
                data-sal-duration='1000'
              >
                <ContactForm
                  ref={(node) => {
                    child = node;
                  }}
                  data={texts}
                  pages={pages}
                  lang={lang}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
      <div className='managers'>
        <div className='wrap-full pl-xl pr-xxl wrap-managers'>
          {items.map((item, index) => {
            return (
              <div
                data-sal='slide-up'
                data-sal-delay={(index % 3) * 400}
                data-sal-duration='800'
                key={index}
                className='manager'
              >
                <img src={item.image} alt={item.title} title={item.title} />
                <h2
                  className='h3'
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <h4
                  className='role'
                  dangerouslySetInnerHTML={{ __html: item.role }}
                />
                <div dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            );
          })}
        </div>
      </div>
      */}
    </Layout>
  );
};
